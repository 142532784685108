import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal, Tooltip } from 'antd';
import type { BaseData, PaginationData } from 'egenie-utils';
import { ExportStore, NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { OrderGridStore } from '../components/orderGrid/store';
import api from '../../utils/api';
import CourierQueryStore from './components/courierQuery/courierQueryStore';
import ManuallyShipStore from './components/manuallyShip/store';
import { GRID_COLUMNS, ITEM_HEADER_COLUMNS } from './constant';
import RemarkModalStore from './components/remarkModal/store';
import ReturnGoodsStore from './components/returnGoods/store';
import styles from './index.less';

interface IOrderStatus {
  name: string;
  code: number;
  number: number;
}

interface IVendor {
  id: number;
  vendorName: string;
  shopName: string;
  shopNo: string;
}

interface IUserRes {
  id: number;
  showName: string;
}

interface Warehouse {
  id: number;
  warehouseName: string;
}

export default class Store {
  constructor() {
    this.programme.filterItems.updateFilterItem([
      {
        type: 'select',
        field: 'dateType',
        label: '日期类型',
        value: 'create_time',
        showItem: false,
      },
      {
        type: 'dateRange',
        field: 'orderTime',
        label: '下单日期',
      },
      {
        type: 'select',
        field: 'orderType',
        label: '订单类型',
        data: [
          {
            label: '手工下单',
            value: '1',
          },
          {
            label: '代发订单',
            value: '2',
          },
        ],
        showItem: false,
      },
      {
        type: 'input',
        field: 'mallReturnOrderNo',
        label: '无忧退货单号',
        showItem: false,
      },
      {
        type: 'select',
        field: 'noCareReturn',
        label: '无忧退货',
        value: '0',
        data: [
          {
            label: '全部',
            value: '0',
          },
          {
            label: '已购买',
            value: '2',
          },
          {
            label: '未购买',
            value: '1',
          },
        ],
        showItem: false,
      },
    ]);
    this.getCloudWarehouse();
    this.getPerms();
    this.queryOrderStatus();
    this.queryVendorList();
    this.getPageParams();
    this.initInfo();
  }

  @observable public returnGoodsStore = new ReturnGoodsStore(this);

  @observable public courierQueryStore = new CourierQueryStore(this);

  @observable public exportStore = new ExportStore({ parent: this });

  @observable public manuallyShipStore = new ManuallyShipStore({ parent: this });

  @observable public remarkModalStore = new RemarkModalStore({ parent: this });

  @observable public resultVisible = false; // 支付反馈结果弹窗

  @observable public returnGoodsVisible = false; // 无忧退货弹窗

  @observable public loading = false;

  @observable public orderStatusList = [];// 订单状态列表

  @observable public vendorList = [];// 档口列表

  @observable public userList = [];// 下单账号

  @observable public tabOrderStatus = -1;// tab页选中的订单状态

  @observable public selectOrderList;// 订单列表(id)

  @observable public isNeedDelivery = false;// 是否需要缺货发货

  @observable public perms = [];

  // 获取档口
  @action public queryVendorList = async(shopNo = '') => {
    try {
      const res = await request<PaginationData<IVendor>>({
        url: api.getOperationShopList,
        data: {
          page: 1,
          pageSize: 200,
          shopNo,
        },
        method: 'POST',
      });
      const list = this.mapSelectList(res.data.list || [], 'shopNo', 'id');
      this.programme.filterItems.addDict({ sellerVendorIds: list });
    } catch (e) {
      console.log(`初始化信息有误${e}`);
    }
  };

  @observable public programme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        type: 'select',
        field: 'dateType',
        label: '日期类型',
        value: 'create_time',
      },
      {
        type: 'dateRange',
        field: 'orderTime',
        label: '日期',
      },
      {
        type: 'select',
        field: 'orderType',
        label: '订单类型',
        data: [
          {
            label: '手工下单',
            value: '1',
          },
          {
            label: '代发订单',
            value: '2',
          },
          {
            label: '衫数样衣订单',
            value: '3',
          },
        ],
      },
      {
        type: 'input',
        field: 'mallReturnOrderNo',
        label: '无忧退货单号',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'orderStatus',
        label: '订单状态',
      },
      {
        type: 'input',
        field: 'mallSaleOrderNo',
        label: '订单编号',
        isMultipleSearch: true,
      },
      {
        type: 'input',
        field: 'receiverName',
        label: '收货人姓名',
      },
      {
        type: 'input',
        field: 'mobile',
        label: '手机号',
      },
      {
        type: 'input',
        field: 'goodsName',
        label: '商品名称',
      },
      {
        type: 'input',
        field: 'goodsId',
        label: '商品ID',
      },
      {
        type: 'input',
        field: 'courierOrderNo',
        label: '快递单号',
      },
      {
        type: 'select',
        field: 'noCareReturn',
        label: '无忧退货',
        value: '0',
        data: [
          {
            label: '全部',
            value: '0',
          },
          {
            label: '已购买',
            value: '2',
          },
          {
            label: '未购买',
            value: '1',
          },
        ],
      },
      {
        type: 'select',
        field: 'lackStock',
        label: '是否缺货',
        data: [
          {
            label: '是',
            value: '1',
          },
          {
            label: '否',
            value: '0',
          },
        ],
      },
      {
        type: 'input',
        field: 'goodsSkuNo',
        label: 'SKU编码',
        isMultipleSearch: true,
      },
      {
        type: 'select',
        field: 'cloudWarehouseId',
        label: '所属云仓',
      },
      {
        type: 'input',
        field: 'buyerName',
        label: '零售商名称',
      },
      {
        type: 'select',
        field: 'tenantRange',
        label: '数据范围',
        value: '0',
        data: [
          {
            label: '测试数据',
            value: '-1',
          },
          {
            label: '非测试数据',
            value: '0',
          },
          {
            label: '全部数据',
            value: '1',
          },
        ],
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'sellerVendorIds',
        label: '档口',
        onSearchCallback: this.queryVendorList,
      },
    ],
    handleSearch: () => {
      return this.handleQuery();
    },
  });

  // 优先发货
  @action public shipPriority = (saleOrderId: number, warehouseId: number): void => {
    Modal.confirm({
      title: '优先发货',
      content: '确认将网仓库存优先分配至该订单吗?',
      onOk: () => {
        request<BaseData<string>>({
          url: api.markPriorityDeliver,
          data: {
            saleOrderId,
            warehouseId,
          },
        });
      },
    });
  };

  // get
  @action public queryOrderStatus = async() => {
    try {
      const orderRes = await request<BaseData<IOrderStatus[]>>({
        url: api.queryGalleryStatus,
        method: 'GET',
      });
      this.orderStatusList = orderRes.data;
      const orderSelectList = this.mapSelectList(this.orderStatusList || [], 'name', 'code');
      this.programme.filterItems.addDict({ orderStatus: orderSelectList });
    } catch (e) {
      console.log(`初始化信息有误${e}`);
    }
  };

  // 缺货发货
  @action public handleShortageDeliver = (id) => {
    Modal.confirm({
      title: '提示',
      content: '缺货发货将通知云仓按已拿款式数量发货，同时未发货部分将原路退款给零售商，确认操作缺货发货吗？',
      onOk: () => {
        return request<BaseData<any>>({
          url: api.shortageDeliver,
          params: { mallSaleOrderId: id },
        })
          .then((res) => {
            message.success(res.data || '操作成功');
            this.queryOrderStatus();
            this.handleQuery();
          })
          .catch((e) => {
            console.log('缺货发货error', e);
          });
      },
    });
  };

  // 关闭订单
  @action public cancelOrder = async(id) => {
    try {
      const res = await request<BaseData<any>>({
        url: api.cancelSaleOrder,
        params: { id },
      });
      message.success(res.data || '操作成功');
      this.queryOrderStatus();
      this.handleQuery();
    } catch (e) {
      console.log(e);
    }
  };

  @action public getPerms = (): any => {
    request<BaseData<any>>({
      url: `${api.getPerms}?resourceId=60050`,
      method: 'get',
    })
      .then((res) => {
        const array2Perms = (arr) => {
          let arrTmp = [];
          if (arr.length == 0) {
            return [];
          }
          arrTmp = arr.map((item) => item.split('_')[1]);
          return arrTmp;
        };
        const perms = array2Perms(res.data || []);
        this.perms = perms;
      });
  };

  // 获取初始信息（订单状态,档口信息）
  @action public initInfo = async() => {
    try {
      const userRes = await request<BaseData<IUserRes[]>>({
        url: api.getUsersList,
        method: 'GET',
      });
      this.userList = userRes.data.map((item) => item.id);
      const userSelectList = this.mapSelectList(userRes.data || [], 'showName', 'id');
      this.programme.filterItems.addDict({
        deliveryType: [
          {
            label: '物流配送',
            value: '0',
          },
          {
            label: '档口自提',
            value: '1',
          },
        ],
        userId: userSelectList,
        dateType: [
          {
            label: '下单时间',
            value: 'create_time',
          },
          {
            label: '付款时间',
            value: 'pay_time',
          },
          {
            label: '发货时间',
            value: 'deliver_goods_time',
          },
        ],
      });
      this.handleQuery();
    } catch (e) {
      console.log(`初始化信息有误${e}`);
    }
  };

  @action public mapSelectList = (originList, key, value) => {
    return originList.map((item) => ({
      label: item[key],
      value: item[value],
    }));
  };

  // 处理下单时间
  private dealOrderTime = (time: string) => {
    if (time) {
      const timeArr = time.split(',');
      return {
        dateValueStart: timeArr[0] || '',
        dateValueEnd: timeArr[1] || '',
      };
    }
    return {};
  };

  // 选中顶部tab
  @action public handleTabsClick = (code: number): void => {
    this.tabOrderStatus = code;
    this.programme.filterItems.updateFilterItem([
      {
        field: 'orderStatus',
        showItem: code < 0,
      },
    ]);

    console.log(this.orderGridStore.pageInfo);

    // 清空分页信息
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page: 1,
    };
    this.handleQuery();
  };

  @action public handlePageChange = (page: number, pageSize: number): void => {
    this.orderGridStore.pageInfo = {
      ...this.orderGridStore.pageInfo,
      page,
      pageSize,
    };
    this.handleQuery();
  };

  // 主表查询
  @action public handleQuery = (): Promise<unknown> => {
    const filterItems = { ...this.programme.filterItems.params };
    this.loading = true;

    // 处理时间
    const {
      orderTime,
      vendorIds,
      sellerVendorIds,
      noCareReturn,
    } = filterItems;
    const timeObj = this.dealOrderTime(orderTime as string);
    delete filterItems.orderTime;

    // 订单状态
    if (this.tabOrderStatus >= 0) {
      delete filterItems.orderStatus;
      filterItems.orderStatus = `${this.tabOrderStatus}`;
    }

    // 无忧退货
    if (noCareReturn === '0') {
      delete filterItems.noCareReturn;
    } else {
      filterItems.noCareReturn = Number(filterItems.noCareReturn) as unknown as string;
    }

    // 卖家名称
    if (sellerVendorIds) {
      filterItems.sellerVendorIds = [sellerVendorIds] as string[];
    }

    if (filterItems.mallSaleOrderNo) {
      filterItems.mallSaleOrderNo = filterItems.mallSaleOrderNo.toString()
        .split(',');
    }

    // 档口
    if (filterItems.sellerVendorIds) {
      filterItems.sellerVendorIds = filterItems.sellerVendorIds[0].split(',');
    }
    const pageInfo = this.orderGridStore.pageInfo;

    // 分页
    const paginationInfo = {
      page: pageInfo.page,
      pageSize: pageInfo.pageSize,
    };
    const data = {
      ...filterItems,
      ...timeObj,
      ...paginationInfo,
      sidx: 'createdAt',
      sord: 'desc',
    };

    const queryPromise = request<PaginationData>({
      url: api.queryGalleryList,
      method: 'POST',
      data,
    })
      .then((res) => {
        this.loading = false;

        const {
          calTotalPageCount,
          first,
          page,
          pageSize,
          totalCount,
          totalPageCount,
          list,
        } = res.data;

        this.orderGridStore.list = list;
        this.orderGridStore.pageInfo = {
          page: page || 1,
          pageSize: pageSize || 10,
          total: totalCount,
        };
      })
      .catch(() => {
        this.selectOrderList = [];
        this.loading = false;
      });

    return queryPromise;
  };

  public orderGridStore = new OrderGridStore({
    list: [],
    primaryKey: 'mallSaleOrderId',
    detailField: 'detailPcVos',
    detailHeight: 90,
    detailKey: 'mallSaleOrderDetailId',
    itemHeaderColumns: ITEM_HEADER_COLUMNS(this),
    gridColumns: GRID_COLUMNS(this),
    itemHeaderRightContent: (row) => {
      return (
        <div className={styles.message}>
          <Tooltip
            title={row?.message}
          >
            <span className={styles.text}>
              买家留言：
              {row?.message ?? '-'}
            </span>
          </Tooltip>
        </div>
      );
    },
    showRefresh: true,
    onPageChange: this.handlePageChange,
  });

  // 导出
  public exportOrder = () => {
    const selectedIds = this.orderGridStore.selectedIds;
    const params = this.programme.filterItems.params;
    let queryParamShow = this.programme.filterItems.translateParams.join(' ');
    if (this.tabOrderStatus !== -1) {
      const orderStatusName = this.orderStatusList.find((el) => el.code === this.tabOrderStatus)?.name || '';
      params.orderStatus = `${this.tabOrderStatus}`;
      queryParamShow += ` 订单状态:${orderStatusName}`;
    }

    console.log(queryParamShow);
    const fileName = '展厅订单导出';
    if (selectedIds.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow(fileName, 'gallery_order', '', {}, queryParamShow.trim(), params); // ids不传即代表导出全部数据
        },
      });
      return;
    }

    this.exportStore.onShow(fileName, 'gallery_order', selectedIds.join(','), {}, queryParamShow.trim(), params); // 勾选部分数据
  };

  // 二次确认弹窗
  @action public confirmAgain = (url, id, method, tipsContent) => {
    Modal.confirm({
      title: '提示',
      content: `${tipsContent}`,
      onOk: () => {
        this.operateCollect(url, id, method);
      },
    });
  };

  // 操作集合
  @action public operateCollect = (url, id, method) => {
    const params: any = {};
    if (method === 'post') {
      params.id = id;
    }
    this.loading = true;
    request<BaseData<any>>({
      url: `${url}?id=${id}`,
      method,
      data: params,
    })
      .then((res) => {
        this.loading = false;
        message.success('操作成功');
        this.queryOrderStatus();
        this.handleQuery();
      })
      .catch(() => this.loading = false);
  };

  // 关闭支付结果反馈弹窗
  public onCloseResult = (): void => {
    this.resultVisible = false;
  };

  // 支付回调
  public payCallback = (): void => {
    this.resultVisible = true;
    this.queryOrderStatus();
    this.handleQuery();
  };

  @action public deliveryProduct = (id) => {
    request<BaseData<any>>({
      url: '',
      method: 'post',
      data: {},
    })
      .then((res) => {
        this.loading = false;
        message.success('操作成功');
        this.queryOrderStatus();
        this.handleQuery();
      })
      .catch(() => this.loading = false);
  };

  // 点击无忧退货标签
  @action public onClickReturnGoods = (orderId: number | string, orderType: number): void => {
    this.returnGoodsStore.onDrawerShow(orderId, orderType);
  };

  @action public jumpToGoodsDetail = (goodsId: number): void => {
    // window.top.egenie.openTab();
  };

  // 获取云仓仓库
  @action
  public getCloudWarehouse = async() => {
    const req = await request<BaseData<Warehouse[]>>({ url: api.findCloudWarehouseList });
    const arr = req.data?.map((item) => ({
      label: item.warehouseName,
      value: `${item.id}`,
    })) || [];
    this.programme.filterItems.updateFilterItem([
      {
        type: 'select',
        field: 'cloudWarehouseId',
        label: '发货仓库',
        data: arr,
      },
    ]);
  };

  // 打开手动发货弹窗
  public openManuallyShipModal = (orderId: number, orderStatus: number): void => {
    if (orderStatus === 5) {
      Modal.confirm({
        title: '提示',
        icon: <ExclamationCircleOutlined/>,
        content: '该订单已生成拿货任务，如果正常拿货并入库发货，此时操作手动发货可能会重复分账，确定要手动发货吗？',
        onOk: () => {
          this.manuallyShipStore.onModalShow(orderId, 'select');
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      this.manuallyShipStore.onModalShow(orderId, 'select');
    }
  };

  // 获取页面跳转参数
  private getPageParams = (): void => {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const orderNo = search.get('orderNo');
    orderNo && this.programme.filterItems.updateFilterItem([
      {
        field: 'mallSaleOrderNo',
        type: 'input',
        value: orderNo,
      },
    ]);
  };

  // 打开备注弹窗
  @action public onClickRemark = (orderId: number, remark: string): void => {
    this.remarkModalStore.onModalShow('select', remark || '', orderId);
  };
}

export type IParentStore = Store;
