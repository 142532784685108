import { message, Modal, Popover, Space } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { ExportStore, ImgFormatter, MainSubStructureModel, NormalProgramme, Permission } from 'egenie-utils';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import JSZip from 'jszip'; // 打包压缩必备，参考链接https://stuk.github.io/jszip/
import { action, autorun, computed, observable } from 'mobx';
import moment from 'moment';
import QRCode from 'qrcode';
import React from 'react';
import { getGalleryId } from '../../utils';
import api from '../../utils/api';
import { arriveLogModal } from './components/arriveLog';
import ArriveLogStore from './components/arriveLog/store';
import { BatchOrderStore } from './components/batchOrder/store';
import { BatchReturnStore } from './components/batchReturn/store';
import { PrintStore } from './components/print/store';
import { SetSubareaStore } from './components/setSubarea/store';
import SelectStyleStore from './selectStyle/store';
import styles from './index.less';
import type { Area, Category, City, IAreaList, Tag } from './interface';
import type { ShelveOptions } from './types';
import { AddStatus, ApproveStatus, GoodsStatus, ShelveStatus } from './types';

export default class ShowroomStore {
  constructor() {
    this.init();
  }

  @observable public currentShowroomId = ''; // 展厅id

  @observable public joinStatus = '-1'; // 加入状态

  @observable public awaitReturnNum: number; // 待归还样衣数量

  @observable public offShelfNum = 0;

  @observable public shoppingCartCount = 0; // 购物车商品数量

  @observable public batchOrderStore: BatchOrderStore;

  @observable public setSubareaStore: SetSubareaStore;

  @observable public arriveLogStore: ArriveLogStore;

  @observable public printStore: PrintStore;

  @observable public batchReturnStore = new BatchReturnStore(this);

  @observable public unshelfNum = 0; // 已下架款式数量

  @observable public areaListQrcodeInfo: IAreaList[] = [];

  @observable public downloadBtnLoading = false;

  @observable public showRoomAddress = '';

  @observable public tenantType = '';

  public exportStore = new ExportStore({ parent: this });

  public selectStyleStore = new SelectStyleStore({ parent: this });

  @computed
  public get selectedRows() {
    return this.grid.gridModel.selectRows;
  }

  @computed
  public get selectedNum() {
    return Array.from(this.grid.gridModel.selectedIds).length;
  }

  @computed
  public get selectedIds() {
    return Array.from(this.grid.gridModel.selectedIds, (id) => (String(id)));
  }

  // 获取档口
  @action
  public getShop = async(shopNo = '') => {
    const shopReq = await request<PaginationData>({
      url: api.getOperationShopList,
      method: 'POST',
      data: {
        page: 1,
        pageSize: 200,
        shopNo,
      },
    });
    const shopList = JSON.parse(JSON.stringify(shopReq?.data.list)
      .replace(/"shopNo"/g, '"label"')
      .replace(/"id"/g, '"value"'));
    this.programme.filterItems.addDict({ shopId: shopList });
  };

  public grid = new MainSubStructureModel({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          resizable: false,
          width: 260,
          formatter: ({ row }) => {
            const {
              addStatus,
              shelveStatus,
              galleryGoodsId,
            } = row;
            return (
              <div>
                {addStatus === 1 && (
                  <Space>
                    <Permission permissionId="75_501">
                      <a onClick={() => this.handleClickSinglePrint(row)}>
                        打印
                      </a>
                    </Permission>
                    {(shelveStatus === 0 || shelveStatus === 2) && (
                      <Permission permissionId="75_604">
                        <a onClick={() => this.pushAndWithdrawGoods(galleryGoodsId, 1)}>
                          上架
                        </a>
                      </Permission>
                    )}
                    {shelveStatus === 1 && (
                      <Permission permissionId="75_4051">
                        <a onClick={() => this.pushAndWithdrawGoods(galleryGoodsId, 0)}>
                          下架
                        </a>
                      </Permission>
                    )}
                    {
                      !this.tenantType.includes('100027') ? (
                        <Permission permissionId="75_374">
                          <a onClick={() => this.handleClickCancelAdd(row)}>
                            取消加入
                          </a>
                        </Permission>
                      ) : null
                    }
                    <Permission permissionId="75_371">
                      <a onClick={() => this.setSubareaStore.changeVisible(true, this.currentShowroomId, [galleryGoodsId])}>
                        设置分区
                      </a>
                    </Permission>
                    <Permission permissionId="75_372">
                      <a onClick={() => this.handleClickOrder(row)}>
                        加购
                      </a>
                    </Permission>
                  </Space>
                )}
                {
                  (addStatus === 0 || addStatus === 2) && (
                    <Permission permissionId="75_373">
                      <Space>
                        <a onClick={() => this.confirmAdd([galleryGoodsId])}>
                          确认加入
                        </a>
                      </Space>
                    </Permission>
                  )
                }
              </div>
            );
          },
        },
        {
          key: 'goodsInfo',
          name: '商品信息',
          width: 360,
          resizable: false,
          formatter: ({ row }) => {
            const {
              goodsName,
              goodsId,
              mainUrl,
              vendorName,
              goodsNo,
            } = row;
            return (
              <div className={styles.goodsInfo}>
                <ImgFormatter
                  borderRadius="4px"
                  height={56}
                  value={mainUrl || ' '}
                  width={56}
                />
                <div className={styles.info}>
                  <div
                    className={`${styles.productItemName} ${styles.lh18}`}
                    onClick={() => this.jumpToStyleDetail(goodsId)}
                    title={goodsName}
                  >
                    {goodsName}
                  </div>
                  <div className={`${styles.lh18}`}>
                    {`档口 ${vendorName || ''}`}
                  </div>
                  <div className={`${styles.lh18}`}>
                    {`货号 ${goodsNo || ''}`}
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'addStatus',
          name: '加入状态',
          ejlHidden: this.tenantType.includes('100027'),
          formatter: ({ row }) => {
            const {
              addStatus,
              addTime,
              cancelTime,
            } = row;
            return (
              <div className={styles.addStatus}>
                <div>
                  {AddStatus[addStatus] || ''}
                </div>
                {addStatus === 1 && (
                  <div>
                    {addTime}
                  </div>
                )}
                {addStatus === 2 && (
                  <div>
                    {cancelTime}
                  </div>
                )}
              </div>
            );
          },
          width: 150,
        },
        {
          key: 'shelveStatus',
          name: '展厅上架状态',
          width: 120,
          formatter: ({ row }) => {
            const {
              shelveStatus,
              addStatus,
            } = row;
            return (
              <div>
                {addStatus === 1
                  ? (
                    <div>
                      {ShelveStatus[shelveStatus] || ''}
                    </div>
                  ) : '-'}
              </div>
            );
          },
        },
        {
          key: 'approveStatus',
          name: '审核状态',
          width: 80,
          formatter: ({ row }) => {
            const { approveStatus } = row;
            return (
              <div>
                {ApproveStatus[approveStatus] || ''}
              </div>
            );
          },
        },
        {
          key: 'status',
          name: '商品状态',
          width: 80,
          formatter: ({ row }) => {
            const { status } = row;
            return (
              <div>
                {GoodsStatus[status] || ''}
              </div>
            );
          },
        },
        {
          key: 'salePrice',
          name: '销售价',
          width: 80,
        },
        {
          key: 'sampleFeeMode',
          name: '是否免费',
          width: 80,
          formatter: ({ row }) => {
            const { sampleFeeMode } = row;
            return (
              <div>
                {sampleFeeMode === 0 ? '免费' : '收费'}
              </div>
            );
          },
        },
        {
          key: 'cycle',
          name: '退样周期(天)',
          width: 120,
        },
        {
          key: 'galleryAreaName',
          name: '分区',
          width: 120,
          formatter: ({ row }) => {
            return (
              <div>
                {row.galleryAreaName || '无'}
              </div>
            );
          },
        },
        {
          key: 'stockNum',
          name: '展厅库存',
          width: 80,
          formatter: ({ row }) => {
            return (
              <div>
                {row.stockNum || 0}
              </div>
            );
          },
        },
        {
          key: 'arrivalCount',
          name: '到货记录',
          width: 80,
          formatter: ({ row }) => {
            return (
              <Popover
                arrowPointAtCenter
                className={styles.popover}
                content={arriveLogModal(this.arriveLogStore)}
                onOpenChange={(visible) => this.onClickArriveCount(visible, row?.arrivalRecordDetailVos)}
                trigger="click"
              >
                <div
                  className={styles.arriveCont}
                >
                  {row.arrivalCount || 0}
                </div>
              </Popover>
            );
          },
        },
        {
          key: 'latestReturnDate',
          name: '最近退货日期',
          width: 160,
          formatter: ({ row }) => {
            return (
              <div>
                {row?.latestReturnDate || '-'}
              </div>
            );
          },
        },
        {
          key: 'createTime',
          name: '商品发布时间',
          width: 160,
        },
        {
          key: 'sampleReturnable',
          name: '是否可退',
          width: 80,
        },
        {
          key: 'orderTime',
          name: '下单记录(件)',
          width: 50,
          formatter: ({ row }) => {
            const {
              bookCount,
              latestOrderTime,
            } = row;
            return (
              <div className={styles.addStatus}>
                <div>
                  {`${bookCount}`}
                </div>
                <div>
                  {`${latestOrderTime || ''}`}
                </div>
              </div>
            );
          },
        },
        {
          key: 'cooperateMode',
          name: '合作模式',
          width: 80,
          ejlHidden: !this.tenantType.includes('100027'),
        },
        {
          key: 'cooperateStatus',
          name: '合作状态',
          width: 80,
          ejlHidden: !this.tenantType.includes('100027'),
        },
        {
          key: 'cooperateUsefulStartTime',
          name: '合作有效期',
          width: 340,
          ejlHidden: !this.tenantType.includes('100027'),
          formatter: ({ row }) => {
            return (
              row.cooperateMode !== '买断' ? (
                <span>
                  {`${row.cooperateUsefulStartTime}至${row.cooperateUsefulEndTime}`}
                </span>
              ) : (
                <span>
                  -
                </span>
              )
            );
          },
        },
      ].map((el) => {
        return {
          resizable: true,
          sortable: false,
          ...el,
        };
      }),
      rows: [],
      primaryKeyField: 'galleryGoodsId',
      sortByLocal: false,
      showCheckBox: true,
      showNoSearchEmpty: true,
      rowHeight: 72,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-ts-gallery/showroomManage/index',
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        const dealtData = this.dealFilterParams(filterParams);
        this.queryAwaitReturnNum();
        this.queryUnshelfNum();
        return request({
          url: api.queryShowroomGoods,
          method: 'post',
          data: {
            ...rest,
            ...dealtData,
            addStatus: this.joinStatus === '-1' ? undefined : this.joinStatus,
            galleryId: this.currentShowroomId,
          },
        });
      },
    },

    hiddenSubTable: true,
  });

  // 处理搜索条件
  private dealFilterParams = (filterParams) => {
    if (Object.prototype.hasOwnProperty.call(filterParams, 'joinTime')) {
      const [
        addBeginTime,
        addEndTime,
      ] = filterParams.joinTime?.split(',');
      delete filterParams.joinTime;
      Object.assign(filterParams, {
        addBeginTime,
        addEndTime,
      });
    }
    if (Object.prototype.hasOwnProperty.call(filterParams, 'price')) {
      const [
        minSalePrice,
        maxSalePrice,
      ] = filterParams.price?.split(',');
      delete filterParams.price;
      Object.assign(filterParams, {
        minSalePrice,
        maxSalePrice,
      });
    }
    if (Object.prototype.hasOwnProperty.call(filterParams, 'hallTime')) {
      const [
        bindingTimeStart,
        bindingTimeEnd,
      ] = filterParams.hallTime?.split(',');
      delete filterParams.hallTime;
      Object.assign(filterParams, {
        bindingTimeStart,
        bindingTimeEnd,
      });
    }
    if (filterParams.tags) {
      filterParams.tags = filterParams.tags.split(',');
    }
    if (filterParams.createTime) {
      const [
        start,
        end,
      ] = filterParams.createTime?.split(',');
      filterParams.createBeginTime = start;
      filterParams.createEndTime = end;
      delete filterParams.createTime;
    }
    if (filterParams.returnable) {
      filterParams.returnable = Boolean(filterParams.returnable !== '0');
    }
    if (filterParams.approveStatus) {
      filterParams.approveStatus = Array.from(filterParams.approveStatus?.split(',') || [], (el) => Number(el));
    }
    return filterParams;
  };

  @action private onChangeCycle = (value?: string): void => {
    const reg = /^(0?|-?[1-9]\d*)$/;
    this.programme.filterItems.updateFilterItem([
      {
        type: 'input',
        field: 'cycle',
        value: reg.test(value) ? value : '',
      },
    ]);
  };

  public programme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'goodsName',
        label: '名称',
      },
      {
        type: 'input',
        field: 'goodsNo',
        label: '货号',
      },
      {
        type: 'dateRange',
        field: 'joinTime',
        label: '加入时间',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'select',
        field: 'shelveStatus',
        label: '展厅上架状态',
        showSearch: false,
        data: [
          {
            label: '已下架',
            value: '0',
          },
          {
            label: '已上架',
            value: '1',
          },
          {
            label: '待上架',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        field: 'approveStatus',
        label: '审核状态',
        showSearch: false,
        mode: 'multiple',
        data: [
          {
            label: '未审核',
            value: '0',
          },
          {
            label: '审核通过',
            value: '1',
          },
          {
            label: '审核驳回',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        field: 'status',
        label: '商品状态',
        showSearch: false,
        data: [
          {
            label: '仓库中',
            value: '2',
          },
          {
            label: '出售中',
            value: '3',
          },
        ],
      },
      {
        type: 'inputNumberGroup',
        field: 'price',
        label: '销售价',
      },
      {
        type: 'dateEnd',
        field: 'returnSampleEndTime',
        label: '退样日期',
        format: 'YYYY-MM-DD',
        placeholder: '请选择',
      },
      {
        type: 'treeSelect',
        field: 'categoryId',
        label: '类目',
        allowClear: true,
        showSearch: true,
      },
      {
        type: 'select',
        field: 'shopId',
        label: '档口',
        onSearchCallback: this.getShop,
      },
      {
        type: 'select',
        field: 'cityId',
        label: '城市',
      },
      {
        type: 'dateRange',
        field: 'hallTime',
        label: '设置展厅时间',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'tags',
        label: '标签',
      },
      {
        type: 'select',
        field: 'galleryAreaId',
        label: '分区',
      },
      {
        type: 'select',
        field: 'sampleFeeMode',
        label: '是否免费',
        data: [
          {
            label: '免费',
            value: '0',
          },
          {
            label: '收费',
            value: '1',
          },
        ],
      },
      {
        type: 'select',
        field: 'stock',
        label: '库存',
        data: [
          {
            label: '无库存',
            value: '0',
          },
          {
            label: '有库存',
            value: '1',
          },
        ],
      },
      {
        type: 'input',
        field: 'cycle',
        label: '退样周期(天)',
        onChangeCallback: this.onChangeCycle,
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '商品发布时间',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'select',
        field: 'returnable',
        label: '是否可退',
        data: [
          {
            label: '可退',
            value: '1',
          },
          {
            label: '不可退',
            value: '0',
          },
        ],
      },
    ],
    count: 6,
    handleSearch: this.grid.gridModel.onQuery,
  });

  public init = async(): Promise<void> => {
    this.arriveLogStore = new ArriveLogStore({ parent: this });
    this.batchOrderStore = new BatchOrderStore(this);
    this.setSubareaStore = new SetSubareaStore(this);
    this.printStore = new PrintStore(this);
    this.grid.getFilterParams = () => this.programme.filterItems.params;
    await this.queryShowroom();
    this.queryUnshelfNum();
    this.queryAwaitReturnNum();
    this.getCategory();
    this.getShop();
    this.getCity();
    this.getTag();
    this.getArea();
    this.getShoppingCartCount();
    this.getAreaQrCodeInfo();
    this.getUserInfo();
    const res = await request<BaseData<{ address: string; }>>({ url: api.getGalleryId });
    this.showRoomAddress = res.data?.address;
  };

  private getUserInfo = async() => {
    const results = await request<any>({
      url: '/api/dashboard/user',
      method: 'get',
    });
    this.tenantType = results.tenantType;
  };

  public onRefresh = (): void => {
    setTimeout(() => {
      this.grid.gridModel.onRefresh();
    }, 1000);
    this.queryAwaitReturnNum();
  };

  // 获取展厅列表
  @action public queryShowroom = async(): Promise<void> => {
    const currentShowroomId = await getGalleryId();
    this.currentShowroomId = currentShowroomId;
  };

  // 批量导出
  public handleClickExport = (): void => {
    const params = this.dealFilterParams(this.programme.filterItems.params);
    const queryParamShow = this.programme.filterItems.translateParams.join(' ');
    const fileName = '展厅款式批量导出';
    const sheetName = 'gallery_goods';
    if (this.selectedIds.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow(fileName, sheetName, '', {}, queryParamShow.trim(), {
            ...params,
            galleryId: this.currentShowroomId,
          }); // ids不传即代表导出全部数据
        },
      });
      return;
    }
    this.exportStore.onShow(fileName, sheetName, this.selectedIds.join(','), {}, queryParamShow.trim(), params); // 勾选部分数据
  };

  // 批量打印
  public handleClickPrint = (): void => {
    if (this.selectedNum > 0) {
      const printList = this.selectedRows.filter((el) => el.approveStatus === 1);
      const goodsIds = printList.map((el) => el.goodsId);
      if (goodsIds.length === 0) {
        message.warning('未审核通过款式不支持打印');
        return;
      }

      // if (!this.selectedRows.every((el) => el.stockNum)) {
      //   message.warning('请选择库存不为0的商品打印');
      //   return;
      // }
      Modal.confirm({
        title: `共勾选${this.selectedNum}个款式，确定依次打印条码吗?`,
        onOk: () => {
          if (goodsIds.length !== this.selectedNum) {
            message.info(`未审核通过款式不支持打印，本次打印${goodsIds.length}条`);
          }
          this.printStore.onShow(goodsIds, !this.selectedRows.every((el) => el.stockNum));
        },
      });
    } else {
      message.warning('请选择款式');
    }
  };

  // 单条打印
  public handleClickSinglePrint = (row): void => {
    if (row.approveStatus !== 1) {
      message.warning('未审核通过款式不支持打印');
      return;
    }

    // if (!row.stockNum) {
    //   message.warning('请选择库存不为0的商品打印');
    //   return;
    // }
    this.printStore.onShow([(row.goodsId || '')], !row.stockNum);
  };

  // 批量加入
  public handleClickBatchAdd = (): void => {
    if (this.selectedNum <= 0) {
      message.warning('请选择款式');
    } else {
      const onlyUnAdd = this.selectedRows.every((el) => el.addStatus !== 1);
      onlyUnAdd ? Modal.confirm({
        title: `共勾选${this.selectedNum}个款式, 确认加入吗?`,
        onOk: () => this.confirmAdd(this.selectedIds),
      }) : message.warning('请选择未加入款式');
    }
  };

  // 批量下单
  @action
  private handleClickOrder = async(row): Promise<void> => {
    if (row.approveStatus !== 1 || row.status !== 3) {
      message.warning('商品状态异常无法下单，请选择审核通过且出售中的商品!');
      return;
    }
    await request({
      url: api.addShoppingCart,
      method: 'post',
      data: {
        galleryGoodsId: row.galleryGoodsId,
        galleryId: row.galleryId,
        goodsId: row.goodsId,
      },
    });
    this.getShoppingCartCount();
    message.success('加购成功');
  };

  // 批量申请退货
  public handleBatchReturn = () => {
    if (!this.selectedRows.length) {
      message.warning('请选择款式!');
      return;
    }

    if (this.selectedIds.length > 100) {
      message.warning('批量退货最多支持勾选100款！');
      return;
    }

    // 库存大于0
    if (this.selectedRows.some((item) => item.stockNum === 0)) {
      message.warning('批量退货必须选择库存大于0的款式！');
      return;
    }
    this.batchReturnStore.toggleModal(true, this.selectedIds, this.currentShowroomId);
  };

  // 批量移出
  @action
  public handleBatchRemove = () => {
    if (!this.selectedRows.length) {
      message.warning('请选择款式!');
      return;
    }

    if (this.selectedRows?.some((el) => el.bookCount > 0)) {
      message.warning('部分款式已有下单记录，移出失败');
      return;
    }
    Modal.confirm({
      title: '确认移出展厅？',
      onOk: async() => {
        await request({
          url: api.batchRemove,
          method: 'POST',
          data: { ids: this.selectedIds.join(',') },
        });
        message.success('操作成功');
        this.onRefresh();
      },
    });
  };

  // 批量设置分区
  @action
  public handleClickSubarea = () => {
    if (!this.selectedRows.length) {
      message.warning('请选择款式!');
      return;
    }
    if (this.selectedRows.some((item) => item.addStatus !== 1)) {
      message.warning('请勾选已加入状态的款式!');
      return;
    }
    this.setSubareaStore.changeVisible(true, this.currentShowroomId, this.selectedIds);
  };

  // 查看待归还样衣
  public checkAwaitReturn = (): void => {
    this.programme.filterItems.reset();
    this.programme.filterItems.updateFilterItem([
      {
        type: 'dateEnd',
        field: 'returnSampleEndTime',
        value: moment()
          .add(7, 'days'),
      },
      {
        type: 'select',
        field: 'stock',
        value: '1',
      },
    ]);
    this.joinStatus = '1';
    this.programme.handleSearch();
  };

  // 查看平台已下架待处理
  public checkOffShelfGoods = (): void => {
    this.programme.filterItems.updateFilterItem([
      {
        field: 'shelveStatus',
        value: '1',
      },
    ]);
    this.programme.handleSearch();
  };

  // 确认加入
  public confirmAdd = (galleryGoodsIds: string[]): void => {
    request({
      url: api.confirmAdd,
      method: 'post',
      data: {
        galleryId: this.currentShowroomId,
        galleryGoodsIds,
      },
    })
      .then((res: BaseData<string>) => {
        message.success(res.info || '操作成功');
        if (!this.tenantType.includes('100027')) {
          this.setSubareaStore.changeVisible(true, this.currentShowroomId, galleryGoodsIds);
        }
        this.onRefresh();
      });
  };

  // 上架下架
  public pushAndWithdrawGoods = (galleryGoodsId: string, operation: 0 | 1): void => {
    request({
      url: api.pushAndWithdraw,
      method: 'post',
      data: {
        galleryGoodsId,
        galleryId: this.currentShowroomId,
        operation,
      },
    })
      .then((res: BaseData<string>) => {
        message.success(res.info || '操作成功');
        this.onRefresh();
      });
  };

  // 待归还样衣件数查询
  @action public queryAwaitReturnNum = async(): Promise<void> => {
    const res: BaseData<number> = await request({ url: `${api.awaitReturnNum}?galleryId=${this.currentShowroomId}` });
    this.awaitReturnNum = res?.data || 0;
  };

  // 取消加入
  public handleClickCancelAdd = async(row) => {
    await request({
      url: '/api/gallery/rest/galleryGoods/cancel/add',
      method: 'post',
      data: {
        galleryGoodsIds: [row.galleryGoodsId],
        galleryId: this.currentShowroomId,
      },
    });
    message.success('取消加入成功');
    this.onRefresh();
  };

  // 跳转款式详情
  public jumpToStyleDetail = (goodsId: number): void => {
    window.top.egenie.openTab(`/egenie-boss/styleDetail/index?id=${goodsId}`, goodsId, '款式详情');
  };

  // 获取类别
  @action
  public getCategory = async() => {
    const categoryReq = await request<BaseData<Category[]>>({
      url: '/api/mall/operation/rest/gim/category/findAll',
      method: 'get',
      params: {
        businessType: 1,
        categoryType: 25,
      },
    });

    const handleData = (originData: Category[]) => {
      const data = originData.map((el) => {
        return {
          value: el.categoryId.toString(),
          title: el.categoryName,
          children: el.children.length > 0 ? handleData(el.children) : [],
        };
      });

      return data;
    };

    const treeData = handleData(categoryReq?.data || []);
    this.programme.filterItems.updateFilterItem([
      {
        type: 'treeSelect',
        field: 'categoryId',
        treeData,
      },
    ]);
    this.batchOrderStore.normalProgramme.filterItems.updateFilterItem([
      {
        type: 'treeSelect',
        field: 'categoryId',
        treeData,
      },
    ]);
    this.selectStyleStore.addModalStore.filterSet.filterItems.updateFilterItem([
      {
        type: 'treeSelect',
        field: 'categoryId',
        treeData,
      },
    ]);
  };

  // 获取货源地
  @action public getCity = async(): Promise<void> => {
    const res: BaseData<City[]> = await request({ url: api.getMarketCity });
    const cityList = res.data?.map((el) => {
      return {
        label: el.cityName,
        value: `${el.cityId}`,
      };
    });

    this.programme.filterItems.addDict({ cityId: cityList });
  };

  // 获取标签
  @action public getTag = async(): Promise<void> => {
    const res: BaseData<Tag[]> = await request({ url: api.getTagList });
    const tagList = res.data?.map((el) => {
      return {
        label: el.tagName,
        value: `${el.tagId}`,
      };
    });
    tagList.unshift(
      {
        label: '空',
        value: '00000',
      },
      {
        label: '非空',
        value: '11111',
      }
    );
    this.programme.filterItems.addDict({ tags: tagList });
  };

  // 获取分区
  @action public getArea = async(): Promise<void> => {
    const res: BaseData<Area[]> = await request({ url: `${api.queryAreaList}?galleryId=${this.currentShowroomId}` });
    const areaList = res.data?.map((el) => {
      return {
        label: el.galleryAreaName,
        value: `${el.galleryAreaId}`,
      };
    });
    this.programme.filterItems.addDict({ galleryAreaId: areaList });
  };

  // 顶部tab条件改变
  @action public onTabKeyChange = (activeKey: string) => {
    this.joinStatus = activeKey;
    this.grid.gridModel.current = 1;

    // 待加入和取消加入无加入时间
    this.programme.filterItems.updateFilterItem([
      {
        field: 'joinTime',
        showItem: !(this.joinStatus === '0' || this.joinStatus === '2'),
      },
    ]);
    this.grid.onQuery();
  };

  // 点击到货记录
  private onClickArriveCount = (visible: boolean, arrivalRecordDetailVos: unknown[]) => {
    if (visible) {
      this.arriveLogStore.logGrid.rows = arrivalRecordDetailVos || [];
    } else {
      this.arriveLogStore.clearRow();
    }
  };

  @action private getShoppingCartCount = async(): Promise<void> => {
    const res: BaseData<number> = await request({ url: api.getShoppingCartCount });
    this.shoppingCartCount = res.data;
  };

  @action private getAreaQrCodeInfo = async(): Promise<void> => {
    const res: BaseData<IAreaList[]> = await request({ url: `${api.getAreaQrCodeInfo}/${this.currentShowroomId}` });
    this.areaListQrcodeInfo = res.data;
  };

  @action public openBatchOrderVisible = (): void => {
    if (!this.shoppingCartCount) {
      message.warning('请加购款式至购物车');
      return;
    }
    this.batchOrderStore.changeVisible(true, this.currentShowroomId);
  };

  // 批量上下架
  @action public batchShelveOrUnshelve = (operation: ShelveOptions): void => {
    request({
      url: api.batchShelveOrUnshelve,
      method: 'post',
      data: {
        operation,
        galleryGoodsIds: this.selectedIds,
      },
    })
      .then((res: BaseData<unknown>) => {
        message.success(res?.info || '操作成功');
        this.onRefresh();
      });
  };

  // 批量取消加入
  public batchCancelJoin = () => {
    if (this.selectedNum <= 0) {
      message.warning('请选择款式！');
      return;
    }

    Modal.confirm({
      title: '是否取消加入已选中的款式',
      onOk: () => {
        return request({
          url: api.batchCancelAdd,
          method: 'POST',
          data: {
            galleryId: this.currentShowroomId,
            galleryGoodsIds: this.selectedIds,
          },
        })
          .then((res: BaseData<unknown>) => {
            message.success('批量取消加入操作成功');
            this.onRefresh();
          });
      },
    });
  };

  // 查看平台已下架的款式
  public checkUnshelfStyle = (): void => {
    this.programme.filterItems.reset();
    this.programme.filterItems.updateFilterItem([
      {
        type: 'select',
        field: 'approveStatus',
        value: [
          '0',
          '2',
        ],
      },
      {
        type: 'select',
        field: 'shelveStatus',
        value: '1',
      },
    ]);
    this.joinStatus = '-1';
    this.programme.handleSearch();
  };

  // 批量上架
  public batchShelf = (): void => {
    if (this.selectedNum <= 0) {
      message.warning('请选择款式！');
      return;
    }

    // if (this.selectedRows.some((el) => el.shelveStatus === 1)) {
    //   message.warning('请选择未上架或待上架款式上架');
    //   return;
    // }

    Modal.confirm({
      title: '是否上架已选中的款式',
      onOk: () => {
        this.batchShelveOrUnshelve(1);
      },
    });
  };

  // 批量下架
  public batchUnshelf = (): void => {
    if (this.selectedNum <= 0) {
      message.warning('请选择款式！');
      return;
    }

    // if (this.selectedRows.some((el) => el.shelveStatus !== 1)) {
    //   message.warning('请选择已上架款式下架');
    //   return;
    // }

    Modal.confirm({
      title: '是否下架已选中的款式',
      onOk: () => {
        this.batchShelveOrUnshelve(0);
      },
    });
  };

  // 批量加购
  public batchAddCart = (): void => {
    if (this.selectedNum <= 0) {
      message.warning('请选择款式！');
      return;
    }
    request({
      url: api.batchAddCart,
      method: 'post',
      data: { ids: this.selectedIds.join(',') },
    })
      .then((res: BaseData<boolean>) => {
        if (res?.data) {
          message.info('部分款式未加入展厅，系统已自动过滤');
          message.success('加入成功');
        }
        this.getShoppingCartCount();
      });
  };

  // 获取已下架款式数量
  @action public queryUnshelfNum = async(): Promise<void> => {
    const res: BaseData<number> = await request({ url: api.queryUnshelfNum });
    this.unshelfNum = res.data || 0;
  };

  // 下载分区二维码 zip
  @action public downloadAreaQrcode = (): void => {
    this.downloadBtnLoading = true;
    setTimeout(() => {
      const zip = new JSZip();
      const promises = [];
      const fileName = `展厅分区二维码 ${moment()
        .format('YYYY-MM-DD')}`;
      this.areaListQrcodeInfo?.forEach(async({
        galleryAreaId,
        galleryAreaName,
        galleryName,
        galleryId,
      }) => {
        const url = `${window.location.origin}/wx/shopkeeper/showRoomDetail?id=${galleryId}&name=${galleryName}&areaId=${galleryAreaId}&address=${this.showRoomAddress}`;
        try {
          await this.pushPromise(url, galleryAreaId, galleryAreaName, promises, zip, fileName);
        } catch (error) {
          this.downloadBtnLoading = false;
        }
      });

      Promise.all(promises)
        .then(() => {
          zip
            .generateAsync({ type: 'blob' })
            .then((content) => {
              // 生成二进制流
              FileSaver.saveAs(content, `${fileName}.zip`);
            });
        })
        .finally(() => {
          this.downloadBtnLoading = false;
        });
    });
  };

  @action public pushPromise = (url: string, galleryAreaId: number, galleryAreaName: string, promises: Array<Promise<unknown>>, zip: JSZip, fileName: string): void => {
    promises.push(
      new Promise((resolve, reject) => {
        this.getFile(url, galleryAreaId)
          .then((res: Blob) => {
            // 获取文件名
            const galleryAreaFileName = `${galleryAreaName}.png`;
            zip.file(`${fileName}/${galleryAreaFileName}`, res, { binary: true });
            resolve(true);
          })
          .catch(() => {
            this.downloadBtnLoading = false;
          });
      })
    );
  };

  @action public getImgUrl = async(url: string): Promise<string> => {
    const res = await QRCode.toDataURL(url);
    return res;
  };

  // 下载图片
  @action public getFile = (url: string, galleryAreaId: number): Promise<unknown> => {
    return new Promise((resolve, reject) => {
      const canvas2 = document.createElement('canvas');
      const _canvas = document.createElement('div');
      const img = document.createElement('img');
      this.getImgUrl(url)
        .then((qrcode) => {
          img.src = qrcode;
          img.width = 200;
          img.height = 200;
          _canvas.id = `${galleryAreaId}`;
          _canvas.style.width = '200px';
          _canvas.style.height = '200px';
          _canvas.style.zIndex = '-999999';
          _canvas.style.display = 'flex';
          _canvas.style.justifyContent = 'center';
          _canvas.style.alignItems = 'center';
          document.body.appendChild(_canvas);
          _canvas.appendChild(img);
          const w = parseInt(window.getComputedStyle(_canvas).width, 10);
          const h = parseInt(window.getComputedStyle(_canvas).height, 10);
          canvas2.width = w;
          canvas2.height = h;
          const context = canvas2.getContext('2d');
          context.scale(1, 1);

          // html转化成canvas
          html2canvas(_canvas, {
            canvas: canvas2,
            scale: 1,
            useCORS: true,
          })
            .then((canvas) => {
              canvas.toBlob((blob) => {
                document.body.removeChild(_canvas);
                resolve(blob);
              });
            })
            .catch(() => {
              this.downloadBtnLoading = false;
            });
        })
        .catch(() => {
          this.downloadBtnLoading = false;
        });
    });
  };
}
